<template>
  <div class="about-tabs">
    <ul class="about-tabs__container">
      <Tab
        v-for="(tab, index) in tabs"
        :key="index"
        :index="index"
        :tab="tab"
      />
    </ul>
  </div>
</template>
<script>
import Tab from "./CareerTab.vue";
export default {
  data() {
    return {
      tabs: [
        {
          text: "VacancyApply",
          link: "work",
        },
        {
          text: "Vacancies",
          link: "vacancy",
        },
      ],
    };
  },
  components: {
    Tab,
  },
};
</script>