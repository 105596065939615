<template>
  <div class="about-page">
    <page-cover :cover="cover" v-if="cover" />
    <CareerContent />
  </div>
</template>
<script>
import PageCover from "@/components/shared/PageCover.vue";
import CareerContent from "@/components/career/CareerContent.vue";
import { mapGetters } from "vuex";

export default {
  name: "career",
  computed: {
    ...mapGetters({
      cover: "pageCover/getCareer",
    }),
  },
  created() {
    this.$store.dispatch("getLevels");
    this.$store.dispatch("getProfessions");
    this.$store.dispatch("getInstitutions");
    this.$store.dispatch("getWeekDays");
  },
  components: {
    PageCover,
    CareerContent,
  },
};
</script>