<template>
  <li class="about-tabs__item">
    <router-link
      :to="{ name: tab.link }"
      class="about-tabs__link"
      active-class="active"
      exact
    >
      {{ $store.state.statics[tab.text] }}
    </router-link>
  </li>
</template>
<script>
export default {
  props: {
    index: {
      type: Number,
      required: true,
    },
    tab: {
      type: Object,
      required: true,
    },
  },
};
</script>