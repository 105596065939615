<template>
  <div class="career">
    <div class="container">
      <div class="row" v-if="$store.state.lang !== 'de'">
        <div class="col-12 mb-5">
          <div class="career__tabs">
            <career-tabs />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12" >
          <transition
            enter-active-class="animate__animated animate__fadeInRight animate__faster"
            leave-active-class="animate__animated animate__fadeOutLeft animate__faster"
            mode="out-in"
          >
            <router-view></router-view>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CareerTabs from "./CareerTabs.vue";
export default {
  components: {
    CareerTabs,
  },
};
</script>